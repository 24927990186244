<template>
  <div id="transactions">
    <h1 class="hidden-md-and-down">{{ $t('transactions.title') }}</h1>
    <h2 class="title hidden-lg-and-up">{{ $t('transactions.title') }}</h2>

    <v-sheet dark class="header">
      <span class="header__item header__item-date">{{ $t('transactions.headers.date') }}</span>
      <span class="header__item header__item-id">{{ $t('transactions.headers.id') }}</span>
      <span class="header__item header__item-event">{{ $t('transactions.headers.event') }}</span>
      <span class="header__item header__item-price">{{ $t('transactions.headers.price') }}</span>
    </v-sheet>

    <div class="body">
      <div class="line" v-for="payment in filteredPayments" :key="payment.id">
        <span class="line__item line__item-date">{{ $d(Date.parse(payment.purchaseDate)) }}</span>
        <span class="line__item line__item-id">{{ payment.id }}</span>
        <span class="line__item line__item-event">{{ payment.event.name }}</span>
        <span class="line__item line__item-price">{{ payment.price }} $</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GET_PAYMENTS,
  PAYMENTS_MODULE,
} from '@/stores/umanize-app/actions/payments/payments.actions';

export default {
  name: 'Transactions',
  computed: {
    ...mapGetters(PAYMENTS_MODULE, ['payments']),
    filteredPayments() {
      return (this.payments || []).filter((payment) => payment.status === 'succeeded');
    },
  },
  methods: {
    ...mapActions(PAYMENTS_MODULE, [GET_PAYMENTS]),
  },
  mounted() {
    this[GET_PAYMENTS]();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

#transactions {
  min-height: calc(100vh - 6rem);
}

.transactions {
  height: 100%;
}

.title {
  text-align: center;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 0.5rem 1rem;

  width: 100%;

  &__item {
    width: 50%;
    padding: 0.5rem;
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 0.5rem;

  width: 100%;

  border-bottom: 2px solid var(--v-primary-base);

  &__item {
    word-break: break-all;
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0.5rem;
  }
}

@include breakpoint(small) {
  .header {
    flex-wrap: nowrap;
    padding: 0.5rem 2rem;

    &__item {
      width: auto;
    }

    &__item-date {
      flex: 1;
    }

    &__item-id {
      flex: 3;
    }

    &__item-event {
      flex: 3;
    }

    &__item-price {
      flex: 1;
    }
  }

  .body {
    padding: 0 2rem;
  }

  .line {
    flex-wrap: nowrap;

    &__item {
      width: auto;
    }

    &__item-date {
      flex: 1;
    }

    &__item-id {
      flex: 3;
    }

    &__item-event {
      flex: 3;
    }

    &__item-price {
      flex: 1;
    }
  }
}
</style>
